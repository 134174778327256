import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import settings from '../../../settings';

const favicon = `${settings.IMAGES_BASE_URL}/images/favicon/mini-favicon.png`;
function SEO({ lang, meta, title, description }) {
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: description || `Live the dream of stunt driving in a MINI on our closed course with professional instructors, slide into a Jturn, learn tricks and more.`
        }
      ].concat(meta)}
    >
      <link rel="icon" href={favicon} />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default SEO;
