import React from 'react';
import Layout from '../../components/mini/layout';
import { Link } from 'gatsby';
import SEO from '../../components/mini/seo';

const SiteMap = () => {
  return(
    <>
    <Layout>
      <SEO title="THE MINI DRIVING EXPERIENCE | MINI SITEMAP" />
      <div className='bmw-mini-site-map'>
        <main>
          <div className='container'>
            <div className='page-header'>SITE MAP</div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>01 - Classes</h5>
              </div>
              <div>
                <div>              
                  <Link to="/mini/schools">Classes</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>02 - Group Events</h5>
              </div>
              <div>
                <div>              
                  <Link to="/mini/groupevents">Group Events</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>03 - Locations</h5>
              </div>
              <div>
                <div>              
                  <Link to="/mini/locations">Locations</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>04 - Booking</h5>
              </div>
              <div>
                <div>              
                  <Link to="https://pds.eventsbmw.com/?_ga=2.68571601.1118896313.1665329309-334664269.1636725789" 
                    target="_blank" 
                    rel="noopener noreferrer">
                    Book your class
                  </Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row multiple-subheader'>
              <div className='header-col'>
                <h5>05 - FAQ and additional information</h5>
              </div>
              <div>
                <div>              
                  <Link to="/mini/instructors">Instructors</Link>
                </div>
                <div>              
                  <Link to="/mini/schoolfaq">School FAQ</Link>
                </div>
                <div>              
                  <Link to="/mini/partners">Partners</Link>
                </div>
                <div>              
                  <Link to="/hotelpartners">Hotel Partners</Link>
                </div>
                <div>              
                  <Link to="/mini/giftcards">Gift Cards</Link>
                </div>
                <div>              
                  <Link to="/press">Press Room</Link>
                </div>
                <div>              
                  <Link to="https://www.bmwusa.com/privacy-policy/index.html" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
                </div>
                <div>              
                  <Link to="/mini/schoolfaq">Cancellation Policy</Link>
                </div>
                <div>              
                  <Link to="/accessibility">Accessibility</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>06 - MINI brand</h5>
              </div>
              <div>
                <div>              
                  <Link to="https://www.miniusa.com/why-mini/programs-and-events/mini-motorsports.html" target="_blank" rel="noopener noreferrer">MINI Motorsport</Link>
                </div>
                <div>              
                  <Link to="https://www.miniusa.com/" target="_blank" rel="noopener noreferrer">MINIUSA.com</Link>
                </div>
              </div>
            </div>
          </div> 
        </main>  
      </div>  
      </Layout>
    </>
  )
}

export default SiteMap;